import {get,post} from "@/utils/axios"
// 获取售后管理列表
const getGoodsSaleList = p => get('/api-seller/apiConsoleGoods/getGoodsSaleList', p);
// 取消售后
const cancelRefund = p => post('/api-seller/apiConsoleGoods/cancelRefund', p);

export default {
    getGoodsSaleList,
    cancelRefund
}
