<template>
  <el-row>
    <el-col class="top">
      <el-col class="page-name">售后管理</el-col>
      <el-col id="order-detail">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 全部 -->
          <el-tab-pane label="全部" name="first" class="pane">
            <el-table
                :data="tableData"
                v-loading="loading">
              <el-table-column
                  prop="orderNo"
                  label="订单号"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsName"
                  label="商品名称"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundStatus"
                  label="申请状态"
              >
                <template slot-scope="scope">
                  <div class="table-status" v-if="scope.row.refundStatus == 2 || scope.row.refundStatus == 4">
                    <div class="fahuo">&nbsp;</div>
                    申请中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 5">
                    <div class="fukuan">&nbsp;</div>
                    处理中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 6 || scope.row.refundStatus == 7 || scope.row.refundStatus == 8">
                    <div class="is-fahuo">&nbsp;</div>
                    已处理
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsType"
                  label="商品类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.softwareGoodsType == 1">软件</el-col>
                  <el-col  v-if="!scope.row.softwareGoodsType == 1">硬件</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="afterSaleType"
                  label="售后类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.afterSaleType == 1">退款</el-col>
                  <el-col  v-if="scope.row.afterSaleType == 2">退货</el-col>
                  <el-col  v-if="!scope.row.afterSaleType == 1 || scope.row.afterSaleType == 2">换货</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareNum"
                  label="数量"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareAllPrice"
                  label="实付金额"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="申请时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundTime"
                  label="退款时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundResultDescription"
                  label="处理结果"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
                  align="center"
              >

                <template slot-scope="scope">
                  <el-button   v-if="scope.row.refundStatus==2 || scope.row.refundStatus==4 || scope.row.refundStatus==5" @click="cancelRefund(scope.row.orderGoodsId,scope.row.goodsSaleId)" >取消退款</el-button>
                  <el-button v-if="scope.row.refundStatus==6 || scope.row.refundStatus==7 || scope.row.refundStatus==8">联系客服</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="'申请中（'+ applying +'）'" name="second" class="pane">
            <el-table
                :data="tableData">
              <el-table-column
                  prop="orderNo"
                  label="订单号"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsName"
                  label="商品名称"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundStatus"
                  label="申请状态"
              >
                <template slot-scope="scope">
                  <div class="table-status" v-if="scope.row.refundStatus == 2 || scope.row.refundStatus == 4">
                    <div class="fahuo">&nbsp;</div>
                    申请中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 5">
                    <div class="fukuan">&nbsp;</div>
                    处理中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 6 || scope.row.refundStatus == 7 || scope.row.refundStatus == 8">
                    <div class="is-fahuo">&nbsp;</div>
                    已处理
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsType"
                  label="商品类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.softwareGoodsType == 1">软件</el-col>
                  <el-col  v-if="!scope.row.softwareGoodsType == 1">硬件</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="afterSaleType"
                  label="售后类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.afterSaleType == 1">退款</el-col>
                  <el-col  v-if="scope.row.afterSaleType == 2">退货</el-col>
                  <el-col  v-if="!scope.row.afterSaleType == 1 || scope.row.afterSaleType == 2">换货</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareNum"
                  label="数量"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareAllPrice"
                  label="实付金额"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="申请时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundTime"
                  label="退款时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundResultDescription"
                  label="处理结果"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-button   v-if="scope.row.refundStatus==2 || scope.row.refundStatus==4 || scope.row.refundStatus==5" @click="cancelRefund(scope.row.orderGoodsId,scope.row.goodsSaleId)" >取消退款</el-button>
                  <el-button v-if="scope.row.refundStatus==6 || scope.row.refundStatus==7 || scope.row.refundStatus==8">联系客服</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="'处理中（'+ processing +'）'" name="third" class="pane">
            <el-table
                :data="tableData">
              <el-table-column
                  prop="orderNo"
                  label="订单号"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsName"
                  label="商品名称"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundStatus"
                  label="申请状态"
              >
                <template slot-scope="scope">
                  <div class="table-status" v-if="scope.row.refundStatus == 2 || scope.row.refundStatus == 4">
                    <div class="fahuo">&nbsp;</div>
                    申请中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 5">
                    <div class="fukuan">&nbsp;</div>
                    处理中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 6 || scope.row.refundStatus == 7 || scope.row.refundStatus == 8">
                    <div class="is-fahuo">&nbsp;</div>
                    已处理
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsType"
                  label="商品类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.softwareGoodsType == 1">软件</el-col>
                  <el-col  v-if="!scope.row.softwareGoodsType == 1">硬件</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="afterSaleType"
                  label="售后类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.afterSaleType == 1">退款</el-col>
                  <el-col  v-if="scope.row.afterSaleType == 2">退货</el-col>
                  <el-col  v-if="!scope.row.afterSaleType == 1 || scope.row.afterSaleType == 2">换货</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareNum"
                  label="数量"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareAllPrice"
                  label="实付金额"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="申请时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundTime"
                  label="退款时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundResultDescription"
                  label="处理结果"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-button   v-if="scope.row.refundStatus==2 || scope.row.refundStatus==4 || scope.row.refundStatus==5" @click="cancelRefund(scope.row.orderGoodsId,scope.row.goodsSaleId)" >取消退款</el-button>
                  <el-button v-if="scope.row.refundStatus==6 || scope.row.refundStatus==7 || scope.row.refundStatus==8">联系客服</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="'已处理（'+ processed +'）'" name="fourth" class="pane">
            <el-table
                :data="tableData">
              <el-table-column
                  prop="orderNo"
                  label="订单号"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsName"
                  label="商品名称"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundStatus"
                  label="申请状态"
              >
                <template slot-scope="scope">
                  <div class="table-status" v-if="scope.row.refundStatus == 2 || scope.row.refundStatus == 4">
                    <div class="fahuo">&nbsp;</div>
                    申请中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 5">
                    <div class="fukuan">&nbsp;</div>
                    处理中
                  </div>
                  <div class="table-status" v-if="scope.row.refundStatus === 6 || scope.row.refundStatus == 7 || scope.row.refundStatus == 8">
                    <div class="is-fahuo">&nbsp;</div>
                    已处理
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareGoodsType"
                  label="商品类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.softwareGoodsType == 1">软件</el-col>
                  <el-col  v-if="!scope.row.softwareGoodsType == 1">硬件</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="afterSaleType"
                  label="售后类型"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-col  v-if="scope.row.afterSaleType == 1">退款</el-col>
                  <el-col  v-if="scope.row.afterSaleType == 2">退货</el-col>
                  <el-col  v-if="!scope.row.afterSaleType == 1 || scope.row.afterSaleType == 2">换货</el-col>
                </template>
              </el-table-column>
              <el-table-column
                  prop="softwareNum"
                  label="数量"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="softwareAllPrice"
                  label="实付金额"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="申请时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundTime"
                  label="退款时间"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="refundResultDescription"
                  label="处理结果"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
                  align="center"
              >
                <template slot-scope="scope">
                  <el-button   v-if="scope.row.refundStatus==2 || scope.row.refundStatus==4 || scope.row.refundStatus==5" @click="cancelRefund(scope.row.orderGoodsId,scope.row.goodsSaleId)" >取消退款</el-button>
                  <el-button v-if="scope.row.refundStatus==6 || scope.row.refundStatus==7 || scope.row.refundStatus==8">联系客服</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col clsaa="paging" id="paging">
        <Page :total="total"  :current="pageNo" :page-size="pageSize"
              @on-page-size-change="onPageSizeChange" @on-change="changePage" show-total show-elevator style="font-size: 14px"></Page>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
Vue.use(iView)
import afterSaleApi from "../../api/buyer/myOrder/afterSaleApi";
export default {
  name: "afterSale",
  data() {
    return {
      loading: true,
      pageSize: 10,
      pageNo: 1,
      total: 1,
      refundStatus: 1,
      activeName: 'first',
      applying: 0,
      processing: 0,
      processed: 0,
      tableData: []
    };
  },
  methods: {
    handleClick(params) {
      this.refundStatus = params.index
      this.getGoodsSaleList();
    },
    onPageSizeChange(index) {
      this.pageSize = index;
      this.getGoodsSaleList();
    },
    changePage(index) {
      this.pageNo = index;
      this.getGoodsSaleList();
    },
    getGoodsSaleList(){
      afterSaleApi.getGoodsSaleList({pageNo:this.pageNo,pageSize:this.pageSize,refundStatus:this.refundStatus}).then((res)=>{
        this.timer = setTimeout(()=>{   //设置延迟执行
          this.loading = false
        },300)
        this.tableData = res.data.goodsSaleList.records
        this.total = res.data.goodsSaleList.total
        this.applying = res.data.applicationCount;
        this.processing = res.data.inProcessCount;
        this.processed = res.data.processedCount;
      })
    },
    cancelRefund (orderGoodsId,goodsSaleId) {
      this.$confirm('确认要取消退款吗？', '是否取消退款').then(() => {
        afterSaleApi.cancelRefund({orderGoodsId:orderGoodsId,goodsSaleId:goodsSaleId}).then((res)=>{
          if (res.code == 200){
            this.buyerMsg('取消退款成功');
            this.getGoodsSaleList();
          }else {
            return this.buyerMsg('取消退款失败', 'error');
          }
        })
      })
    }
  },
  // create() {
  //   this.getGoodsSaleList();
  // },
  mounted() {
    document.body.style.overflow = 'hidden';
    this.getGoodsSaleList();
  }
}
</script>
<style>
.el-button--primary  {
  background: #f65d59 !important ;
  border-color: #f65d59 !important;
}
</style>
<style scoped>
#paging {
  margin-top: 20px;
}
.top {
  margin: 20px 35px;
  position: absolute;
  width: 98%;
  /*min-width: 1130px;*/
}
.table-status {
  display: flex;
}
.table-status div {
  width: 8px;
  height: 8px;
  margin: 5% 5% 0% 0%;
  border-radius: 50%;
}
.fukuan {
  background: #FF1400;
}
.fahuo {
  background: #FF8B00;
}
.is-fahuo {
  background: #A900FF;
}
.wancheng {
  background: #64BA21;
}
  .pane{
      font-size: 14px;
  }
#order-detail {
  margin-right: 20px;
}
  /deep/ #order-detail  .el-tabs__header {
    margin: -11px 0 15px;
  }
  /deep/ #order-detail .el-tabs__nav {
    height: 63px;
  }
  /deep/ #order-detail .el-tabs__item:hover,
  /deep/ #order-detail .el-tabs__item.is-active,
  /deep/ #order-detail .el-icon-search:before {
    color: #F76F66;
  }
  /deep/ #order-detail .el-tabs__active-bar {
    display: none;
  }
  /deep/ #order-detail .el-tabs__item {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 48px;
    font-size: 16px;
  }
  /deep/ #order-detail .el-tabs__nav-wrap::after { display: none}
  .page-name {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  /deep/ .ivu-page {
    float: right;
    margin-right: 143px;
  }
  /deep/ .el-button {
    border: 1px solid #AEAEAE;
    border-radius: 8px;
    color: #AEAEAE;
    width: 73px;
    height: 30px;
    padding: 7px 8px;
  }
</style>
